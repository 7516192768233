<template>
  <form class="contact-form" :class="{ 'contact-form--feedback': hasFeedback }">
    <div
      class="contact-form__feedback"
      v-for="rendered in renderIf(hasFeedback)"
      :key="`hasFeedback_${rendered}`"
      :class="{ 'contact-form__feedback--error': feedback.isError }"
    >
      {{ feedback.text }}
    </div>
    <div class="contact-form__input-group">
      <label class="contact-form__input-label">First Name</label>
      <input
        aria-label="Please enter your first name"
        class="contact-form__input"
        :class="{
          'contact-form__input--error': feedback.type === 'firstName'
        }"
        v-model.trim="firstName"
        @input="handleInput()"
      />
    </div>
    <div class="contact-form__input-group">
      <label class="contact-form__input-label">Last Name</label>
      <input
        aria-label="Please enter your last name"
        class="contact-form__input"
        :class="{
          'contact-form__input--error': feedback.type === 'lastName'
        }"
        v-model.trim="lastName"
        @input="handleInput()"
      />
    </div>
    <div class="contact-form__input-group">
      <label class="contact-form__input-label">Email Address</label>
      <input
        aria-label="Please enter your email address"
        class="contact-form__input"
        :class="{
          'contact-form__input--error': feedback.type === 'email'
        }"
        v-model.trim="email"
        type="email"
        @input="handleInput()"
      />
    </div>
    <div class="contact-form__input-group">
      <label class="contact-form__input-label">Message</label>
      <textarea
        aria-label="Please enter your message"
        class="contact-form__text-area"
        :class="{
          'contact-form__text-area--error': feedback.type === 'message'
        }"
        v-model.trim="message"
        rows="4"
        @input="handleInput()"
      ></textarea>
    </div>
    <button
      class="contact-form__submit"
      @click="handleSubmit"
      :disabled="submittingForm"
      :class="{ 'contact-form__submit--disabled': submittingForm }"
    >
      Submit
      <TzSpinner
        v-for="rendered in renderIf(submittingForm)"
        :key="`spinner_${rendered}`"
        :is-check="false"
        spinner-size="15"
        :spinner-color="'#1D1E2A'"
        spinner-stroke="3"
        :spinner-animation="true"
        animation-style="spin"
        :spinner-delay="100"
        :is-starting-spin="true"
      />
    </button>
    <client-only>
      <email-template
        class="contact-form__email-template"
        :body="message"
        :fullName="fullName"
        :email="email"
        :contactEmail="contactEmail"
      />
    </client-only>
  </form>
</template>

<script>
import emailSDK from '@/services/email-sdk';
import TzSpinner from '@trazi/tz-spinner/src/tz-spinner.vue';
import emailTemplate from '@/templates/support-email.vue';
import helpers from '@/assets/js/shared/misc/helpers';
const { renderIf } = helpers;
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const nameRegex = /^([a-zA-Z\s\-]+)$/;

export default {
  name: 'CsContactForm',
  components: {
    TzSpinner,
    emailTemplate
  },
  data() {
    return {
      feedback: {},
      firstName: null,
      lastName: null,
      email: null,
      message: null,
      submittingForm: false,
      renderIf
    };
  },
  props: {
    contactName: {
      type: String
    },
    contactDomain: {
      type: String
    },
    contactEmail: {
      type: String
    }
  },
  computed: {
    hasFeedback: function() {
      return Object.keys(this.feedback).length !== 0;
    },
    fullName: function() {
      return helpers.setTitleCase(`${this.firstName} ${this.lastName}`);
    }
  },
  methods: {
    handleInput() {
      this.feedback = {};
    },
    handleSubmit: async function(e) {
      this.checkForm();

      if (!this.hasFeedback) {
        this.submittingForm = true;
        const subject = `${this.contactName} Contact Us - ${this.fullName}`;

        const emailTemplate = document.getElementById('support-email');
        const body = emailTemplate.outerHTML;
        try {
          await emailSDK(window).General.emailTo(
            {
              domain: this.contactDomain,
              subject,
              body
            },
            this.contactEmail
          );
          this.clearForm();
          this.feedback = {
            text:
              'Thank you for contacting us! We will be in touch with you soon.',
            isError: false
          };
        } catch (err) {
          this.feedback = {
            text:
              "We're sorry, there was an error sending your email, please try again later.",
            isError: true
          };
          console.error(err);
        }
        this.submittingForm = false;
      }
    },
    checkForm: function() {
      this.feedback = {};

      if (!this.firstName) {
        this.feedback = {
          type: 'firstName',
          text: 'Please enter your first name.',
          isError: true
        };
      } else if (!this.validName(this.firstName)) {
        this.feedback = {
          type: 'firstName',
          text:
            'Please only use letters, spaces, and/or dashes when entering your first name',
          isError: true
        };
      } else if (!this.lastName) {
        this.feedback = {
          type: 'lastName',
          text: 'Please enter your last name.',
          isError: true
        };
      } else if (!this.validName(this.lastName)) {
        this.feedback = {
          type: 'lastName',
          text:
            'Please only use letters, spaces, and/or dashes when entering your last name',
          isError: true
        };
      } else if (!this.validEmail()) {
        this.feedback = {
          type: 'email',
          text: 'Please enter a valid email address',
          isError: true
        };
      } else if (!this.validMessage()) {
        this.feedback = {
          type: 'message',
          text: 'Please enter a message of 500 characters or less',
          isError: true
        };
      }
    },
    clearForm() {
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.message = null;
    },
    validEmail: function() {
      return emailRegex.test(this.email);
    },
    validMessage: function() {
      return (
        this.message !== null &&
        this.message !== '' &&
        this.message.length <= 500
      );
    },
    validName: function(name) {
      return nameRegex.test(name);
    }
  }
};
</script>
