var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"contact-form",class:{ 'contact-form--feedback': _vm.hasFeedback }},[_vm._l((_vm.renderIf(_vm.hasFeedback)),function(rendered){return _c('div',{key:("hasFeedback_" + rendered),staticClass:"contact-form__feedback",class:{ 'contact-form__feedback--error': _vm.feedback.isError }},[_vm._v("\n    "+_vm._s(_vm.feedback.text)+"\n  ")])}),_vm._v(" "),_c('div',{staticClass:"contact-form__input-group"},[_c('label',{staticClass:"contact-form__input-label"},[_vm._v("First Name")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.firstName),expression:"firstName",modifiers:{"trim":true}}],staticClass:"contact-form__input",class:{
        'contact-form__input--error': _vm.feedback.type === 'firstName'
      },attrs:{"aria-label":"Please enter your first name"},domProps:{"value":(_vm.firstName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.firstName=$event.target.value.trim()},function($event){return _vm.handleInput()}],"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),_c('div',{staticClass:"contact-form__input-group"},[_c('label',{staticClass:"contact-form__input-label"},[_vm._v("Last Name")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.lastName),expression:"lastName",modifiers:{"trim":true}}],staticClass:"contact-form__input",class:{
        'contact-form__input--error': _vm.feedback.type === 'lastName'
      },attrs:{"aria-label":"Please enter your last name"},domProps:{"value":(_vm.lastName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.lastName=$event.target.value.trim()},function($event){return _vm.handleInput()}],"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),_c('div',{staticClass:"contact-form__input-group"},[_c('label',{staticClass:"contact-form__input-label"},[_vm._v("Email Address")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],staticClass:"contact-form__input",class:{
        'contact-form__input--error': _vm.feedback.type === 'email'
      },attrs:{"aria-label":"Please enter your email address","type":"email"},domProps:{"value":(_vm.email)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.email=$event.target.value.trim()},function($event){return _vm.handleInput()}],"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),_c('div',{staticClass:"contact-form__input-group"},[_c('label',{staticClass:"contact-form__input-label"},[_vm._v("Message")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.message),expression:"message",modifiers:{"trim":true}}],staticClass:"contact-form__text-area",class:{
        'contact-form__text-area--error': _vm.feedback.type === 'message'
      },attrs:{"aria-label":"Please enter your message","rows":"4"},domProps:{"value":(_vm.message)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.message=$event.target.value.trim()},function($event){return _vm.handleInput()}],"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),_c('button',{staticClass:"contact-form__submit",class:{ 'contact-form__submit--disabled': _vm.submittingForm },attrs:{"disabled":_vm.submittingForm},on:{"click":_vm.handleSubmit}},[_vm._v("\n    Submit\n    "),_vm._l((_vm.renderIf(_vm.submittingForm)),function(rendered){return _c('TzSpinner',{key:("spinner_" + rendered),attrs:{"is-check":false,"spinner-size":"15","spinner-color":'#1D1E2A',"spinner-stroke":"3","spinner-animation":true,"animation-style":"spin","spinner-delay":100,"is-starting-spin":true}})})],2),_vm._v(" "),_c('client-only',[_c('email-template',{staticClass:"contact-form__email-template",attrs:{"body":_vm.message,"fullName":_vm.fullName,"email":_vm.email,"contactEmail":_vm.contactEmail}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }