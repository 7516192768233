<template>
  <html
    xmlns="http://www.w3.org/1999/xhtml"
    class="gr__mailtrap_io"
    id="support-email"
  >
    <head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
      />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    </head>
    <body
      style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; background-color: #e4e4e4; color: #74787E; height: 100%; hyphens: auto; line-height: 1.4; margin: 0; -moz-hyphens: auto; -ms-word-break: break-all; width: 100% !important; -webkit-hyphens: auto; -webkit-text-size-adjust: none; word-break: break-word;"
      data-gr-c-s-loaded="true"
    >
      <style>
        @media only screen and (max-width: 600px) {
          .inner-body {
            width: 100% !important;
          }
          .footer {
            width: 100% !important;
          }
        }
        @media only screen and (max-width: 500px) {
          .button {
            width: 100% !important;
          }
        }
      </style>
      <table
        class="wrapper"
        width="100%"
        cellpadding="0"
        cellspacing="0"
        style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; background-color: #f5f8fa; margin: 0; padding: 0; width: 100%; -premailer-cellpadding: 0; -premailer-cellspacing: 0; -premailer-width: 100%;"
      >
        <tbody>
          <tr>
            <td
              align="center"
              style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box;"
            >
              <table
                class="content"
                width="100%"
                cellpadding="0"
                cellspacing="0"
                style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; margin: 0; padding: 0; width: 100%; -premailer-cellpadding: 0; -premailer-cellspacing: 0; -premailer-width: 100%;"
              >
                <tbody>
                  <!-- Email Body -->
                  <tr>
                    <td
                      class="body"
                      width="100%"
                      cellpadding="0"
                      cellspacing="0"
                      style="font-family: Avenir, Helvetica, sans-serif;box-sizing: border-box;background-color: #e4e4e4;border-bottom: 1px solid #e4e4e4;border-top: 1px solid #e4e4e4;margin: 0;padding: 0;width: 100%;-premailer-cellpadding: 0;-premailer-cellspacing: 0;-premailer-width: 100%;"
                    >
                      <table
                        class="inner-body"
                        align="center"
                        width="570"
                        cellpadding="0"
                        cellspacing="0"
                        style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; background-color: #FFFFFF; margin: 0 auto; padding: 0; width: 570px; -premailer-cellpadding: 0; -premailer-cellspacing: 0; -premailer-width: 570px;margin-top:20px;"
                      >
                        <!-- Body content -->
                        <tbody>
                          <tr
                            style="background:linear-gradient(357.09deg,rgba(10, 105, 255, 0.06) 2.42%,rgba(10, 105, 255, 0) 97.53%)"
                          >
                            <td>
                              <table>
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="line-height:0px;font-size:0px;padding:40px 40px 20px 40px;"
                                    >
                                      <img
                                        src="https://contactsource.com/images/logo-full-color.svg"
                                        width="159"
                                        height="29"
                                        alt="ContactSource"
                                        class="resize_img2"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      class="content-cell"
                                      style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; padding: 35px; "
                                    >
                                      <table style="width:100%">
                                        <tbody>
                                          <tr>
                                            <td
                                              style="background-color:#fff;padding:25px;border-radius:8px;"
                                            >
                                              <p
                                                style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; color: #2F3133; font-size: 19px; margin-top: 0; text-align: left;"
                                              >
                                                <span style="font-weight: bold"
                                                  >From:</span
                                                >
                                                {{ fullName }}
                                              </p>
                                              <p
                                                style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; color: #2F3133; font-size: 19px; margin-top: 0; text-align: left;"
                                              >
                                                <span style="font-weight: bold"
                                                  >Email:</span
                                                >
                                                {{ email }}<br />
                                              </p>
                                              <p
                                                style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; color: #2F3133; font-size: 19px; margin-top: 0; text-align: left;"
                                              >
                                                <span style="font-weight: bold"
                                                  >Message:</span
                                                >
                                              </p>
                                              <p
                                                style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; color: #555555; font-size: 16px; line-height: 1.5em; margin-top: 0; text-align: left;"
                                              >
                                                <tz-markdown :content="body" />
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      bgcolor="transparent"
                                    >
                                      <table
                                        width="90%"
                                        border="0"
                                        align="center"
                                        cellpadding="0"
                                        cellspacing="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              height="21"
                                              align="left"
                                              class="hgt"
                                              valign="top"
                                              style="font-size:1px; line-height:1px;"
                                            >
                                              &nbsp;
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="center" valign="middle">
                                              <table
                                                width="100%"
                                                border="0"
                                                align="center"
                                                cellpadding="0"
                                                cellspacing="0"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="center"
                                                      valign="middle"
                                                    >
                                                      <table
                                                        width="100%"
                                                        border="0"
                                                        align="center"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              align="center"
                                                              valign="middle"
                                                              width="200"
                                                              class="split_cont"
                                                            >
                                                              <table
                                                                width="165"
                                                                border="0"
                                                                align="center"
                                                                cellpadding="0"
                                                                cellspacing="0"
                                                                class="res_width"
                                                              >
                                                                <tbody>
                                                                  <tr>
                                                                    <td
                                                                      align="left"
                                                                      valign="top"
                                                                      style="font-family:Arial, Helvetica, sans-serif;font-size: 11px;line-height:13px; color: #6b6b6b; font-weight:400;"
                                                                    ></td>
                                                                    <td
                                                                      align="center"
                                                                      valign="middle"
                                                                      style="font-family:Arial, Helvetica, sans-serif;font-size: 11px;line-height:14px; color: #231f20; font-weight:400;"
                                                                    >
                                                                      ©
                                                                      Copyright
                                                                      2021-2022
                                                                      <br />
                                                                      <a
                                                                        href="https://contactsource.com"
                                                                        style="color: #231f20;text-decoration: none;"
                                                                        >ContactSource.com</a
                                                                      >
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                            <td
                                                              align="center"
                                                              valign="middle"
                                                              width="200"
                                                              class="split_cont"
                                                            ></td>
                                                            <td
                                                              align="center"
                                                              valign="middle"
                                                              width="200"
                                                              class="split_cont"
                                                            >
                                                              <table
                                                                width="120"
                                                                border="0"
                                                                align="center"
                                                                cellpadding="0"
                                                                cellspacing="0"
                                                              >
                                                                <tbody>
                                                                  <tr>
                                                                    <td
                                                                      align="left"
                                                                      valign="middle"
                                                                      style="line-height:0px; font-size:0px;"
                                                                      class="center_align"
                                                                    >
                                                                      <img
                                                                        src="https://contactsource.com/images/logo-full-color.svg"
                                                                        width="103"
                                                                        height="18"
                                                                        alt="ContactSource"
                                                                      />
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      height="21"
                                                      align="left"
                                                      class="hgt"
                                                      valign="top"
                                                      style="font-size:1px; line-height:1px;"
                                                    >
                                                      &nbsp;
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              bgcolor="#e4e4e4"
                              valign="middle"
                              style="font-size: 0px;line-height: 0px;"
                            >
                              <table
                                width="100%"
                                border="0"
                                align="center"
                                cellpadding="0"
                                cellspacing="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      height="85"
                                      align="left"
                                      class="hgt"
                                      valign="top"
                                      style="font-size:0px; line-height:0px;"
                                      bgcolor="#e4e4e4"
                                    >
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      bgcolor="#e4e4e4"
                                    >
                                      <table
                                        width="100%"
                                        border="0"
                                        align="center"
                                        cellpadding="0"
                                        cellspacing="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="center"
                                              valign="middle"
                                              style="font-family:Arial, Helvetica, sans-serif;font-size: 12px;line-height:16px; color: #b1b1b1; font-weight:400;"
                                            >
                                              This email was sent by
                                              <a
                                                href="https://contactsource.com"
                                                style="color: #b1b1b1;text-decoration: none;"
                                              >
                                                <span
                                                  style="color: #b1b1b1;font-weight: 700;"
                                                  >{{ email }}</span
                                                >
                                              </a>
                                              <br />
                                              This email was sent to
                                              <span
                                                style="color: #b1b1b1;text-decoration: none;"
                                              >
                                                <span style="color: #b1b1b1;">{{
                                                  contactEmail
                                                }}</span>
                                              </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              height="40"
                                              align="left"
                                              class="hgt"
                                              valign="top"
                                              style="font-size:0px; line-height:0px;"
                                              bgcolor="#e4e4e4"
                                            >
                                              &nbsp;
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </body>
  </html>
</template>

<script>
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
// For updating design, change this file type to .html and remove the template tags so that you can view it easily in the browser
export default {
  name: 'SupportEmail',
  components: {
    TzMarkdown
  },
  props: {
    contactEmail: {
      type: String
    },
    body: {
      type: String
    },
    fullName: {
      type: String
    },
    email: {
      type: String
    }
  }
};
</script>
