<template>
  <div class="home">
    <div id="home-anchor" class="home__section home__page-anchor">
      <tz-banner
        :title="cms.page.hero_title"
        :description="cms.page.hero_description"
        :has-overlay="true"
        :is-large="true"
        :is-page-title="true"
        :sub-text="cms.page.hero_subtext"
        :image="cms.page.hero_image"
      >
        <template>
          <a class="tz-banner__btn" href="#contact" @click="scrollToContact">
            {{ cms.page.hero_btn_text }}
          </a>
        </template>
      </tz-banner>
    </div>
    <div class="home__section-container home__section-container--services">
      <div
        id="services-anchor"
        class="home__section home__section--services home__page-anchor"
      >
        <div class="home__section-overview">
          <h2 class="home__section-heading">
            {{ cms.page.service_section_title }}
          </h2>
          <tz-markdown
            class="home__section-desc"
            :content="cms.page.service_section_description"
          />
        </div>
        <div class="home__section-content">
          <div
            v-for="service in cms.page.services"
            class="home__service-container"
          >
            <div class="home__service-copy">
              <h3 class="home__service-label">{{ service.label }}</h3>
              <tz-markdown
                class="home__service-desc"
                :content="service.content"
              />
            </div>
            <div class="home__service-image-container">
              <img
                class="home__service-image"
                :src="service.image"
                :alt="`${service.label} Icon`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="home__section-container home__section-container--about home__page-anchor"
      id="about-anchor"
    >
      <div class="home__section home__section--about">
        <img
          class="home__about-image"
          :src="cms.page.about_section_image"
          :alt="`About Us Image`"
        />
        <div class="home__section-overview">
          <h2 class="home__section-heading">
            {{ cms.page.about_section_title }}
          </h2>
          <tz-markdown
            class="home__section-desc"
            :content="cms.page.about_section_description"
          />
        </div>
      </div>
    </div>
    <div class="home__section-container home__section-container--contact">
      <div
        id="contact-anchor"
        class="home__section home__section--contact home__page-anchor"
      >
        <div class="home__section-overview">
          <h2 class="home__section-heading">
            {{ cms.page.contact_section.title }}
          </h2>
          <tz-markdown
            class="home__section-desc"
            :content="cms.page.contact_section.description"
          />
        </div>
        <div class="home__contact-form-container">
          <cs-contact-form
            contactName="Contact Source"
            contactDomain="contactsource.com"
            contactEmail="support@contactsource.com"
          />
          <div class="home__contact-info">
            <div
              class="home__contact-info-container home__contact-info-container--phone"
            >
              <div class="home__contact-info-copy">
                <p class="home__contact-info-title">
                  {{ cms.page.contact_section.phone }}
                </p>
                <p class="home__contact-info-blurb">
                  {{ cms.page.contact_section.contact_times }}
                </p>
              </div>
            </div>
            <div
              class="home__contact-info-container home__contact-info-container--email"
            >
              <div class="home__contact-info-copy">
                <p class="home__contact-info-title">
                  {{ cms.page.contact_section.email }}
                </p>
                <p class="home__contact-info-blurb">
                  {{ cms.page.contact_section.email_blurb }}
                </p>
              </div>
            </div>
            <div
              class="home__contact-info-container home__contact-info-container--address"
            >
              <div class="home__contact-info-copy">
                <p class="home__contact-info-title">
                  {{ cms.page.contact_section.address }}
                </p>
                <p class="home__contact-info-blurb">
                  {{ cms.page.contact_section.address_blurb }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TzBanner from '@trazi/tz-banner/src/tz-banner.vue';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
import CsContactForm from '@/components/cs-contact-form.vue';
const pageCms = require(`@/assets/cms/pages/home.json`);
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const nameRegex = /^([a-zA-Z\s\-]+)$/;

export default {
  components: {
    CsContactForm,
    TzBanner,
    TzMarkdown
  },
  props: {
    cms: {
      type: Object,
      default: () => {
        return {
          page: pageCms
        };
      }
    }
  },
  methods: {
    scrollToContact() {
      this.$nuxt.$emit('banner-link-click', 'contact');
    }
  }
};
</script>
